<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="showCreateRoleModal()">
        <PlusOutlined />新增
      </a-button>
    </div>
    <a-table rowKey="menu_id" :columns="columns" :data-source="data" :pagination="false">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'action'">
          <span>
            <a @click="showUpdateRoleModal(record)">编辑</a>
            <a-divider type="vertical" />
            <a @click="showDeleteConfirm(record)">删除</a>
          </span>
        </template>
      </template>
    </a-table>
    <a-modal v-model:visible="visible" :title="title" @ok="handleSubmit" @cancel="onCancel" :width="720">
      <a-form ref="formRef" v-bind="layout" :model="formState">
        <a-form-item has-feedback label="角色名称" name="role_name">
          <a-input v-model:value="formState.role_name" type="text" autocomplete="off" />
        </a-form-item>

        <a-form-item has-feedback label="菜单权限" name="menus">
          <a-tree v-model:checkedKeys="checkedKeys" checkable :tree-data="treeData" :fieldNames="replaceFields" :checkStrictly="true">
          </a-tree>
        </a-form-item>
        <a-form-item has-feedback label="排序" name="sort" extra="数字越小越靠前">
          <a-input class="mini-input" type="number" v-model:value="formState.sort" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import {
  createVNode,
  defineComponent,
  reactive,
  ref,
  onMounted,
  watch,
} from "vue";
import { Modal, message } from "ant-design-vue";
import * as user from "@/views/manage/api";
import * as api from "@/views/manage/api";
const columns = [
  {
    title: "角色ID",
    dataIndex: "role_id",
    width: "10%",
  },
  {
    title: "角色名称",
    dataIndex: "role_name",
    width: "12%",
  },
  {
    title: "排序",
    dataIndex: "sort",
    width: "10%",
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    width: "10%",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "20%",
  },
];
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};
export default defineComponent({
  components: {
    PlusOutlined,
  },
  setup() {
    //搜索
    const param = reactive({
      name: "",
    });
    const title = ref();
    const formRef = ref();
    const formState = reactive({
      role_name: "",
      sort: 100,
      role_id: 0,
      menus: [],
    });
    const visible = ref(false);
    const replaceFields = {
      children: "children",
      title: "title",
      key: "name",
      parent_id: "parent_code",
    };
    const data = ref([]);

    // 角色列表
    // 刷新列表数据
    const handleRefresh = () => {
      user.role_list(param).then((res) => {
        data.value = res.data.list;
      });
    };

    //加载菜单
    const treeData = ref([]);

    const getTreeMenuData = () => {
      api.menu_list({ type: 1 }).then((res) => {
        treeData.value = res.data.list;
      });
    };

    const onCancel = () => {
      checkedKeys.value = [];
      formRef.value.resetFields();
    };
    // 删除数据
    const showDeleteConfirm = (record) => {
      Modal.confirm({
        title: "确定要删除【" + record.role_name + "】这个角色吗?",
        icon: createVNode(ExclamationCircleOutlined),
        content: "删除角色后将无法恢复，请谨慎操作！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          user.role_del({ role_id: record.role_id }).then((res) => {
            message.success(res.message);
            handleRefresh();
          });
        },
        onCancel() {
          // console.log("Cancel");
        },
      });
    };

    //更新角色
    const showUpdateRoleModal = (record) => {
      user.role_detail({ role_id: record.role_id }).then((res) => {
        formState.role_id = record.role_id;
        formState.menus = res.data.menus;
        checkedKeys.value = res.data.menus;
        formState.sort = res.data.sort;
        formState.role_name = res.data.role_name;
        visible.value = true;
        title.value = "编辑角色";
      });
    };
    //添加角色
    const showCreateRoleModal = () => {
      formState.role_id = 0;
      formState.menus = [];
      formState.sort = 100;
      formState.role_name = "";
      visible.value = true;
      title.value = "添加角色";
    };
    //添加和更新角色
    const handleSubmit = () => {
      formRef.value.validate().then(() => {
        formState.menus = checkedKeys.value.checked;
        if (formState.role_id) {
          user.role_update(formState).then((res) => {
            if (res.status == 200) {
              message.success(res.message);
              formRef.value.resetFields();
              visible.value = false;
              // 通知父组件刷新列表
              handleRefresh();
            } else {
              message.error(res.message);
            }
          });
        } else {
          user.role_create(formState).then((res) => {
            if (res.status == 200) {
              message.success(res.message);
              formRef.value.resetFields();
              visible.value = false;
              // 通知父组件刷新列表
              handleRefresh();
            } else {
              message.error(res.message);
            }
          });
        }
      });
    };

    const checkedKeys = ref([]);

    watch(checkedKeys, () => {
      console.log(checkedKeys);
    });
    onMounted(() => {
      handleRefresh();
      getTreeMenuData();
    });

    return {
      formState,
      columns,
      data,
      showDeleteConfirm,
      handleRefresh,
      treeData,
      replaceFields,
      visible,
      showUpdateRoleModal,
      showCreateRoleModal,
      onCancel,
      handleSubmit,
      layout,
      checkedKeys,
      param,
      formRef,
    };
  },
});
</script>